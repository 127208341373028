<template>
  <el-dialog title="合同预览" :visible.sync="visible" width="1000px" :modal-append-to-body="true" :lock-scroll="false"
    :close-on-click-modal="false" @close="close">
    <div class="content">
      <div class="content" v-for="page in pageAllNum" :key="page">
        <pdf :src="src" :page="page"></pdf>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button style="width: 116px" @click="close">取 消</el-button>
      <el-button type="success" style="width: 150px" @click="exportContract">导出</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import pdf from "vue-pdf-signature";
  import CMapReaderFactory from "vue-pdf-signature/src/CMapReaderFactory.js";
  import { selectPdfFile } from "@/api/exchange";
  import { download } from "../../../../api/add";
  export default {
    components: {
      pdf,
    },
    data() {
      return {
        checked: false,
        pageAllNum: 30, // pdf文件总页数
        // pageNum: 1, // pdf文件页码
        src: "",
        pageNum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        baseUrl: window.globalUrl.HOME_API,
      };
    },
    watch: {
      contractInfo: function () {
        if (this.contractInfo) {
          const blob = new Blob(
            [this.contractInfo[0] ? this.contractInfo[0] : this.contractInfo],
            {
              type: "application/pdf;charset=utf-8",
            }
          );
          let src = window.URL.createObjectURL(blob);
          this.getNumPages(src);
          this.src = pdf.createLoadingTask({
            url: src,
            CMapReaderFactory,
          });
        }
      },
      contractArray: function () {
        this.$nextTick(() => {
          if (this.contractArray && this.contractArray.length > 0) {
            const blob = new Blob([this.contractArray[0]], {
              type: "application/pdf;charset=utf-8",
            });
            let src = window.URL.createObjectURL(blob);
            this.getNumPages(src);
            this.src = pdf.createLoadingTask({
              url: src,
              CMapReaderFactory,
            });
          }
        });
      },
    },
    props: ["visible", "contractInfo", "contractArray", "xmbh"],
    methods: {
      async lujing() {
        const res = await selectPdfFile({ xmbh: this.xmbh })
        console.log("lujing", res.data);
        // var arr = JSON.parse(res.data)
        res.data.forEach((item, index) => {
          // 在这里可以访问每个数组元素，例如：
          if (res.data.length - 1 == index) {
            this.download(item)
            console.log("index", index);
            return
          }
        });
      },
      // 执行下载
      async download(file) {
        // let res = await download({ ossFilePath: this.baseUrl + "admin/file/get?ossFilePath="+file.url });
        // console.log("res",res);
        // if (res) {
        let blob = file;
        const fileName = file.name;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        // }
      },

      // 获取PDF页数
      getNumPages(data) {
        let loadingTask = pdf.createLoadingTask(data);
        loadingTask.promise
          .then((pdf) => {
            this.pageAllNum = pdf.numPages;
          })
          .catch((err) => {
            console.error("pdf 加载失败", err);
          });
      },
      // 导出合同和协议
      exportContract() {
        this.lujing()
        console.log("this.contractArray", this.contractArray);
        if (this.contractArray && this.contractArray.length > 0) {
          // this.exportDone(
          //   this.contractArray[0]
          // );
          console.log("------------1111111111-----------");
          
          this.exportDone(this.contractArray[1], "出让监管协议");
          this.exportDone(this.contractArray[0], "出让合同");
        } else {
          console.log("------------2222222222-----------------------");
          
          this.exportDone(this.contractInfo);
        }
      },
      exportDone(data, name) {
        console.log("123", data, name);

        // 提交
        let blob = data;
        let binaryData = [];
        binaryData.push(blob);
        const fileName = name ? name + ".pdf" : this.xmbh + ".pdf";
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);

      },
      // pdf翻页
      // prePage() {
      // 	var page = this.pageNum
      // 	page = page > 1 ? page - 1 : this.pageTotalNum
      // 	this.pageNum = page
      // },
      // 下一页函数
      // nextPage() {
      // 	var page = this.pageNum
      // 	page = page < this.pageTotalNum ? page + 1 : 1
      // 	this.pageNum = page
      // },
      close() {
        // 关闭
        this.$emit("close", false);

      },
    },
  };
</script>

<style lang="less" scoped>
  .tools {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 10px;
  }

  /deep/.el-dialog {
    margin-top: 3vh !important;
  }

  /* .content {
  max-height: 560px;
  overflow: auto;
} */
  .pdf-page {
    border: 1px solid #8b8383;
    margin-bottom: 10px;
  }
</style>
<style lang="less" scoped>
  .land-trust .el-table th {
    background: #0668b3;
    color: #fff;
  }

  .el-dialog {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 0px;
  }

  .pdfView {
    overflow: auto;
    height: 100%;
  }

  .content {
    width: 90%;
    min-width: 860px;
    margin: 10px auto;
    padding: 10px;
    background: rgba(85, 85, 85, 0.1);
    box-sizing: border-box;
    margin-top: 0;
  }

  /deep/.ht {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    box-sizing: border-box;
    height: calc(100% - 40px);
  }

  /deep/.el-dialog__body {
    height: calc(100% - 54px);
    padding-top: 0;
    overflow: auto;
    padding-bottom: 0;
    position: relative;

    .content {
      height: calc(100% - 128px);
    }
  }

  .pdf-page {
    border: 1px solid #8b8383;
    margin-bottom: 10px;
  }
</style>